import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0421\u04d9\u0439\u043a\u0435\u0441\u0442\u0456\u043a\u0442\u0435\u0440\u0020\u0442\u0430\u0431\u044b\u043b\u043c\u0430\u0434\u044b",
        messages: {
            required: '\u04e8\u0440\u0456\u0441\u0442\u0456\u0020\u0442\u043e\u043b\u0442\u044b\u0440\u044b\u04a3\u044b\u0437',
            phone: {
                incorrect: '\u0422\u0435\u043b\u0435\u0444\u043e\u043d\u0020\u043d\u04e9\u043c\u0456\u0440\u0456\u0020\u0436\u0430\u0440\u0430\u043c\u0441\u044b\u0437'
            },
                email: {
                incorrect: '\u041c\u0435\u043a\u0435\u043d\u0436\u0430\u0439\u0434\u044b\u04a3\u0020\u0434\u04b1\u0440\u044b\u0441\u0020\u0435\u043c\u0435\u0441\u0020\u043f\u0456\u0448\u0456\u043c\u0456',
                symbolNotFound: '\u0410\u0434\u0440\u0435\u0441\u0020\u201c\u0040\u201c\u0020\u0442\u0430\u04a3\u0431\u0430\u0441\u044b\u043d\u0020\u049b\u0430\u043c\u0442\u0443\u044b\u0020\u043a\u0435\u0440\u0435\u043a\u002e\u0020\u041c\u0435\u043a\u0435\u043d\u0436\u0430\u0439\u0434\u0430\u0020\u201c\u0040\u201c\u0020\u0431\u0435\u043b\u0433\u0456\u0441\u0456\u0020\u0436\u043e\u049b',
                addressIsNotFull: '\u041c\u0435\u043a\u0435\u043d\u0436\u0430\u0439\u0434\u044b\u0020\u201c\u0040\u201c\u0020\u0442\u0430\u04a3\u0431\u0430\u0441\u044b\u043d\u0430\u043d\u0020\u043a\u0435\u0439\u0456\u043d\u0020\u0435\u043d\u0433\u0456\u0437\u0456\u04a3\u0456\u0437\u002e\u0020\u041c\u0435\u043a\u0435\u043d\u0436\u0430\u0439\u0020\u0442\u043e\u043b\u044b\u049b\u0020\u0435\u043c\u0435\u0441'
            },
            password: {
                minLength: '\u0422\u0430\u04a3\u0431\u0430\u043b\u0430\u0440\u0434\u044b\u04a3\u0020\u0435\u04a3\u0020\u0430\u0437\u0020\u0441\u0430\u043d\u044b: 6. \u0415\u043d\u0434\u0456: %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u0415\u043d\u0433\u0456\u0437\u0456\u043b\u0433\u0435\u043d\u0020\u043f\u0430\u0440\u043e\u043b\u044c\u0434\u0435\u0440\u0020\u0441\u04d9\u0439\u043a\u0435\u0441\u0020\u043a\u0435\u043b\u043c\u0435\u0439\u0434\u0456'
            },
            ofertaAgreement: {
                notChecked: '\u0415\u0440\u0435\u0436\u0435\u043b\u0435\u0440\u0434\u0456\u0020\u049b\u0430\u0431\u044b\u043b\u0434\u0430\u0493\u0430\u043d\u044b\u04a3\u044b\u0437\u0434\u044b\u0020\u0440\u0430\u0441\u0442\u0430\u04a3\u044b\u0437'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});